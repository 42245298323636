<template>
  <LayoutView
    :titulo="`Detalle de asignación #${id}`"
    :fondo="asignacion && asignacion ? asignacion.local.imagen : ''"
    :seccionDetalle="`${estados[3].value}. Estado ${estados[3].title} `"
  >
    <!-- <p v-hide-supervisor>AAAA</p> -->
    <form-wizard
      ref="wizard"
      stepSize="sm"
      color="var(--dark)"
      error-color="var(--dark)"
      title=""
      subtitle=""
      back-button-text="Retroceder"
      next-button-text="Siguiente"
      finish-button-text="Actualizar Asignación"
      @on-complete="openModal"
    >
      <!-- INGRESAR TAREAS -->
      <tab-content
        title="Ingresar precios contratista"
        :before-change="() => validateStep('step1')"
      >
        <PrimerTab
          ref="step1"
          v-if="asignacion"
          @on-validate="mergeData"
        ></PrimerTab>
      </tab-content>
    </form-wizard>

    <b-modal
      id="modal-corroboracion"
      hide-footer
      center
      title="¿Desea confirma su acción?"
    >
      <p class="h5 text-center">
        Por favor confirme su acción de ingresar sus precios.
      </p>
      <p class="h6 font-italic text-center mt-3">
        Si confirma no podrá cambiar los precios luego.
      </p>
      <b-button
        :disabled="loading"
        @click="submitData"
        class="mt-3"
        block
        variant="warning"
      >
        <template v-if="loading">
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span class="sr-only">Asignando...</span>

          <span class="d-inline-block ml-2">Asignando</span>
        </template>

        <span v-else>Asignar Precios</span>
      </b-button>
    </b-modal>
  </LayoutView>
</template>

<script>
import { mapState } from "vuex";
import { estados } from "../../../../.env";
import GetAsignaciones from "@/apollo/queries/asignaciones/GetAsignaciones.gql";

import UpdatePrecioContratista from "@/apollo/mutations/asignaciones/UpdatePreciosContratista.gql";

import LayoutView from "@/layouts/detalles.vue";

import estadoMixins from "@/mixins/estadoMixins.js";
import baseReconocimiento from "@/mixins/baseReconocimiento.js";

import PrimerTab from "@/components/asignaciones/cuartaVista/primerTab.vue";

import { hideAdmin, hideContratante } from "@/directives/roleDirective.js";

export default {
  components: { LayoutView, PrimerTab },
  directives: { hideAdmin, hideContratante },
  mixins: [estadoMixins, baseReconocimiento],
  data() {
    return {
      id: this.$route.params.id,
      estados: estados,
      loading: false,
      asignacion: null,
      tareas: [],
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapState(["datosUsuarioLogueado"]),
  },
  methods: {
    mergeData(model, deliveryDays, isValid) {
      if (isValid) {
        // merging each step model into the final model
        // this.tareas = Object.assign([], this.tareas, model);
        this.asignacion.tiempoEntrega = deliveryDays;
        this.tareas = [...model];
      }
    },
    validateStep(name) {
      var refToValidate = this.$refs[name];
      return refToValidate.validate();
    },
    openModal() {
      this.$bvModal.show("modal-corroboracion");
    },

    privateUpdatePreciosContratista() {
      const tareasFormatted = this.tareas.map(
        (t) =>
          (t = { tareaId: t.tareaId, precioContratista: t.precioContratista })
      );

      return new Promise((resolveFunction) => {
        this.$apollo
          .mutate({
            mutation: UpdatePrecioContratista,
            errorPolicy: "all",
            variables: {
              input: tareasFormatted,
            },
          })
          .then((resTarea) => {
            if (resTarea.errors) {
              this.loading = false;
              const h = this.$createElement;

              const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
                h("b-spinner", { props: { type: "grow", small: true } }),
                ` Los precios de las partidas no se han podido actualizar, intentelo nuevamente `,
                h("b-spinner", { props: { type: "grow", small: true } }),
              ]);
              // Create the title
              const vNodesTitle = h(
                "div",
                {
                  class: [
                    "d-flex",
                    "flex-grow-1",
                    "align-items-baseline",
                    "mr-2",
                  ],
                },
                [h("strong", { class: "mr-2" }, "Precios no actualizados")]
              );

              this.$bvToast.toast([vNodesMsg], {
                title: [vNodesTitle],
                solid: true,
                variant: "danger",
              });
              return;
            }
            resolveFunction();
          });
      });
    },

    submitData() {
      this.loading = true;

      this.privateUpdatePreciosContratista().then(async () => {
        this.updateReconocimientoLocal(this.asignacion, this.id).then(
          async (res) => {
            if (res.errors) {
              this.loading = false;

              const h = this.$createElement;

              const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
                h("b-spinner", { props: { type: "grow", small: true } }),
                ` La asignación no se ha podido actualizar, intentelo nuevamente `,
                h("b-spinner", { props: { type: "grow", small: true } }),
              ]);
              // Create the title
              const vNodesTitle = h(
                "div",
                {
                  class: [
                    "d-flex",
                    "flex-grow-1",
                    "align-items-baseline",
                    "mr-2",
                  ],
                },
                [h("strong", { class: "mr-2" }, "Asignación no actualizada")]
              );

              this.$bvToast.toast([vNodesMsg], {
                title: [vNodesTitle],
                solid: true,
                variant: "danger",
              });
            } else {
              this.loading = false;

              await this.privateActualizarEstadoAsignacion(5);

              const h = this.$createElement;

              const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
                h("b-spinner", { props: { variant: "success", small: true } }),
                ` Asignación #${this.id} actualizada, queda pendiente de revision `,
                h("b-spinner", { props: { variant: "success", small: true } }),
              ]);
              // Create the title
              const vNodesTitle = h(
                "div",
                {
                  class: [
                    "d-flex",
                    "flex-grow-1",
                    "align-items-baseline",
                    "mr-2",
                  ],
                },
                [h("strong", { class: "mr-2" }, "Asignación actualizada")]
              );

              this.$bvToast.toast([vNodesMsg], {
                title: [vNodesTitle],
                solid: true,
                variant: "success",
              });

              this.$router.push({
                name: "asignaciones",
                params: { id: this.id },
              });
            }
          }
        );
      });
    },

    init() {
      this.$apollo
        .query({
          query: GetAsignaciones,
          variables: {
            codigoTicket: this.id,
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          this.asignacion = response.data.GetAsignaciones;
        });
    },

    checkTareas() {
      let isValid = this.$refs.step1.tareas.length > 0;
      if (!isValid) {
        const h = this.$createElement;

        const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
          h("b-spinner", { props: { type: "grow", small: true } }),
          ` Para poder ingresar la foto de la visita se debe por lo menos agregar una partida. `,
          h("b-spinner", { props: { type: "grow", small: true } }),
        ]);
        // Create the title
        const vNodesTitle = h(
          "div",
          {
            class: ["d-flex", "flex-grow-1", "align-items-baseline", "mr-2"],
          },
          [h("strong", { class: "mr-2" }, "Agregue al menos 1 partida")]
        );

        this.$bvToast.toast([vNodesMsg], {
          title: [vNodesTitle],
          solid: true,
          variant: "danger",
        });
      }
      return isValid;
    },
  },
};
</script>

<style lang="scss" scoped>
.galeria-previa {
  &__imagen {
    width: 100%;
    margin: 0 auto;
    border-radius: 0.3rem;
  }

  &__icono {
    font-size: 25px;
    color: red;

    background-color: white;
    padding: 10px;
    border-radius: 50%;
    -webkit-box-shadow: -16px 22px 56px -18px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -16px 22px 56px -18px rgba(0, 0, 0, 0.75);
    box-shadow: -16px 22px 56px -18px rgba(0, 0, 0, 0.75);
    position: absolute;
    top: 0;
    right: 0;
  }

  textarea {
    height: 60px !important;
  }
}

@media (min-width: 768px) {
  .galeria-previa {
    &__icono {
      margin: 20px 20px 0 0;
      right: 1rem;
    }
  }
}
</style>
