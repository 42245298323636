<template>
  <b-modal id="modal-crear-tarea" hide-footer centered title="Registrar partida">
    <form @submit.prevent="crearTarea()">
      <div class="form-row">
        <div class="col-md-12">
          <label for="descripcion">Descripción</label>
          <textarea
            class="form-control"
            name="descripcion"
            id="descripcion"
            v-model="descripcion"
            cols="30"
            rows="10"
          ></textarea>
        </div>
      </div>

      <div class="form-row mt-2">
        <div class="col-md-6">
          <label for="unidadMedida">Unidad de medida</label>
          <input type="text" class="form-control" v-model="unidadMedida" />
        </div>

        <div class="col-md-6">
          <label for="cantidad">Cantidad</label>
          <input
            type="text"
            class="form-control"
            v-model.trim="cantidad"
            @keypress="regexNumber"
          />
        </div>
      </div>

      <div class="form-row mt-2">
        <div class="col-md-12">
          <label for="horario">Asignar horario</label>
          <select id="horario" class="form-control" v-model="horario">
            <option value="0">Diurno</option>
            <option value="1">Nocturno</option>
          </select>
        </div>
      </div>

      <div class="form-group">
        <label for="observacion">Observación</label>
        <textarea
          id="observacion"
          class="form-control"
          v-model="observacion"
        ></textarea>
      </div>

      <div class="text-right mt-3">
        <button
          :disabled="loading"
          type="button"
          class="btn btn-link"
          @click="closeModal"
        >
          Volver
        </button>

        <button
          type="submit"
          class="btn btn-danger"
          :disabled="$v.$invalid || loading"
        >
          <template v-if="loading">
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span class="sr-only">Registrando</span>

            <span class="d-inline-block ml-2">Registrando</span>
          </template>

          <span v-else>Registrar Tarea</span>
        </button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { mapState } from "vuex";
import { validationMixin } from "vuelidate";
const { required } = require("vuelidate/lib/validators");

import CreateTareasSupervisor from "@/apollo/mutations/tareas/CreateTareasSupervisor.gql";

export default {
  mixins: [validationMixin],
  data() {
    return {
      descripcion: "",
      observacion: null,
      unidadMedida: null,
      cantidad: null,
      observacion: "",

      horario: "0",

      loading: false,
    };
  },
  validations: {
    descripcion: { required },
    unidadMedida: { required },
    cantidad: { required },
    horario: { required },
  },

  props: ["codigoTicket"],
  methods: {
    closeModal() {
      this.$bvModal.hide("modal-crear-tarea");
      this.limpiarCampos();
    },
    regexNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    crearTarea() {
      if (this.$v.$invalid) {
        return;
      }

      this.loading = true;

      let { horario, observacion, unidadMedida, cantidad, descripcion } = this;

      this.$apollo
        .mutate({
          mutation: CreateTareasSupervisor,
          variables: {
            input: [
              {
                horario: parseInt(horario),
                observacion,
                unidadMedida,
                estado: 1,
                cantidad: parseInt(cantidad),
                precioSupervisor: 0,
                descripcion,
                codigoTicket: this.codigoTicket,
              },
            ],
          },
        })
        .then(() => {
          this.$emit("success");

          this.loading = false;

          this.$bvModal.hide("modal-crear-tarea");

          this.$toast.success(`Tarea agregada`, {
            // override the global option
            position: "top-right",
          });

          this.limpiarCampos();
        })
        .catch(() => {
          this.loading = false;

          this.$toast.error("Ocurrió un error, inténtelo nuevamente", {
            // override the global option
            position: "top-right",
          });
        });
    },
    limpiarCampos() {
      this.observacion = "";
      this.unidadMedida = null;
      this.descripcion = "";
      this.cantidad = null;
      this.horario = "0";
    },
  },
  computed: {
    ...mapState(["datosUsuarioLogueado"]),
  },
};
</script>
