<template>
  <div class="row" v-if="asignacion">
    <div class="col">
      <!-- PRECIO Y TIEMPO SECCION IMPORTANTE -->
      <div>
        <p class="h3">Precio de Oferta y Tiempo de Entrega</p>
        <hr />
        <div class="row my-5">
          <!-- TIEMPO DE ENTREGA -->
          <div class="col-12 col-md-12">
            <p class="h5 text-center mb-3 font-weight-bold font-italic">
              Tiempo de Entrega
            </p>
            <b-input-group append="dias hábiles" class="mb-2 mr-sm-2 mb-sm-0">
              <b-form-input
                size="lg"
                v-model="deliveryDays"
                placeholder="Digite cuantos dias tomaría realizar la entrega"
                @keypress="regexNumberInt"
                type="number"
              ></b-form-input>
            </b-input-group>

            <p>
              El tiempo de entrega que se generará será de
              <span class="font-weight-bold font-italic">
                {{ deliveryDays }} dias hábiles</span
              >
            </p>
          </div>

          <!-- PRECIO DE OFERTA -->
          <!-- <div class="col-12 col-md-5 mt-5 mt-md-0">
              <p class="h5 text-center mb-3 font-weight-bold font-italic">
                Precio de Oferta (S/.)
              </p>

              <b-input-group prepend="S/." class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input
                  size="lg"
                  type="number"
                  step="0.01"
                  min="0.00"
                  @keypress="regexNumber"
                  v-model="offerAmount"
                  placeholder="Digite el monto de oferta por realizar toda la asignación"
                ></b-form-input>
              </b-input-group>

              <p>
                El monto de oferta que se generará será de
                <span class="font-weight-bold font-italic">
                  {{ offerAmount ? formatoAPrecio(offerAmount) : 'S/ 0.00' }}</span
                >
              </p>
            </div> -->
        </div>
      </div>

      <hr />

      <!-- Si ya existe reconocimiento de local -->
      <!-- DEBE EXISTIR TAREAS OBVIAMENTE PARA ESTA PARTE -->
      <template>
        <div class="d-flex justify-content-between align-items-center">
          <p class="h3 detalles-asignacion__sub font-italic my-0">
            Partidas registradas
          </p>
          <button
            type="button"
            v-hide-admin
            class="btn btn-success btn-sm"
            @click="$bvModal.show('modal-crear-tarea')"
          >
            <i class="fas fa-plus mr-2"></i>
            Registrar Partidas
          </button>
        </div>

        <hr />

        <p class="small mt-1 text-center font-italic" v-if="loading">
          <template>
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span class="sr-only"></span>

            <span class="d-inline-block ml-2">Obteniendo Partidas</span>
          </template>
        </p>

        <!-- MUESTRA DE TAREAS ASIGNADAS -->
        <section class="mt-3" v-else>
          <div class="row" v-if="tareas.length">
            <div
              class="col-md-12 mb-3"
              v-for="tarea in tareas"
              :key="tarea.tareaId"
            >
              <!-- Muestra un card con las tareas, cada card es collapse y muestra info detallada de cada tarea -->
              <b-card class="bg-info">
                <div class="d-flex justify-content-between">
                  <div class="d-flex">
                    <a
                      class="text-light"
                      href=""
                      v-b-toggle="tarea.tareaId"
                      @click.prevent
                      variant="info"
                      > {{ tarea.descripcion }}</a
                    >
                    <b-badge
                      variant="success"
                      v-if="tarea.precioContratista"
                      class="ml-2"
                      >Precio colocado</b-badge
                    >
                    <b-badge variant="dark" v-else class="ml-2"
                      >Debe completar</b-badge
                    >
                  </div>
                  <!-- BOTONES DE ACCION -->
                  <div class="d-flex">
                    <button
                      @click="privateOpenEditTarea(tarea)"
                      type="button"
                      class="btn btn-warning btn-sm mr-4"
                      v-b-tooltip.hover
                      title="Editar Partida"
                    >
                      <i class="fas fa-marker"></i>
                    </button>
                    <button
                      @click="eliminarTareaRegistrada(tarea.tareaId)"
                      type="button"
                      class="btn btn-danger btn-sm"
                      v-b-tooltip.hover
                      title="Eliminar Tarea"
                    >
                      <i class="fas fa-trash-alt"></i>
                    </button>
                  </div>
                </div>

                <b-collapse :id="tarea.tareaId" class="mt-2">
                  <hr />

                  <b-card>
                    <p>
                      <i class="fas fa-calendar-alt"></i>
                      {{ tarea.created_at | moment("DD/MM/YYYY") }}
                    </p>

                    <small class="text-muted">Observaciones:</small>

                    <p class="card-text my-1 pb-1 border-bottom">
                      {{ tarea.observacion }}
                    </p>

                    <div class="row mt-2">
                      <div class="col-md-12">
                        <p class="my-1 pb-1 border-bottom">
                          <span class="small text-muted">Descripción</span>
                          <span class="ml-2">
                            {{ tarea.descripcion }}
                          </span>
                        </p>
                      </div>
                    </div>

                    <div class="row mt-2">
                      <div class="col-md-4 col-lg-4">
                        <p class="my-1 pb-1 border-bottom">
                          <span class="small text-muted"
                            >Horario asignado:</span
                          >
                          <span class="ml-2">{{
                            tarea.horario === 0 ? "Diurno" : "Nocturno"
                          }}</span>
                        </p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6">
                        <p class="mt-3 mb-1 pb-1 border-bottom">
                          <span class="small text-muted">Medidas:</span>
                          <span class="ml-2"
                            >{{ tarea.cantidad }} ({{
                              tarea.unidadMedida
                            }})</span
                          >
                        </p>
                      </div>
                      <div class="col-md-6">
                        <div class="my-1 pb-1 border-bottom">
                          <div>
                            <span class="small text-muted"
                              >Asignar precio (Contratista) (S/.) :</span
                            >
                            <br />

                            <input
                              type="number"
                              step="0.01"
                              class="form-control mt-2 mb-2"
                              placeholder="Asignar precio (Contratista)"
                              required
                              @keypress="regexNumber"
                              v-model="tarea.precioContratista"
                            />
                          </div>

                          <div>
                            <span class="small font-weight-bold"
                              >Subtotal:
                              {{
                                tarea.precioContratista
                                  ? formatoAPrecio(
                                      setSubtotal(
                                        tarea.precioContratista,
                                        tarea.cantidad
                                      )
                                    )
                                  : "Por asignar"
                              }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-card>
                </b-collapse>
              </b-card>
            </div>
          </div>
          <div class="row" v-else>
            <div class="col-12">
              <p class="my-0" v-if="esAdmin || esContratista">
                Aún no existen tareas registradas por supervisor.
              </p>

              <p class="my-0" v-if="esSupervisor">
                Aún no existen tareas registradas.
              </p>
            </div>
          </div>
        </section>
      </template>
    </div>

    <modal-crear-tarea
      :codigoTicket="this.id"
      @success="getTareas()"
    ></modal-crear-tarea>

    <modal-editar-tarea
      v-if="selectedEditData"
      :codigoTicket="this.id"
      @close="isEditingTarea = false"
      @success="
        isEditingTarea = false;
        init();
      "
      :data="selectedEditData"
      :active="isEditingTarea"
    ></modal-editar-tarea>

    <modal-eliminar-tarea
      :tareaId="tareaDeleteId"
      @success="getTareas()"
    ></modal-eliminar-tarea>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { precio } from "@/mixins/precio";
import regexMixin from "@/mixins/regexMixins";
import {
  hideAdmin,
  hideContratante,
  hideSupervisor,
} from "@/directives/roleDirective.js";
import { validationMixin } from "vuelidate";
const { required, minValue, integer } = require("vuelidate/lib/validators");

import GetAllTareas from "@/apollo/queries/tareas/GetAllTareas.gql";
import GetAsignaciones from "@/apollo/queries/asignaciones/GetAsignaciones.gql";

import ModalCrearTarea from "@/components/tareas/ModalCrearTareaContratista";
import ModalEliminarTarea from "@/components/tareas/ModalEliminarTarea.vue";
import ModalEditarTarea from "@/components/tareas/ModalEditarTareaContratista.vue";

export default {
  directives: { hideAdmin, hideContratante, hideSupervisor },

  mixins: [precio, regexMixin, validationMixin],
  components: {
    ModalCrearTarea,
    ModalEliminarTarea,
    ModalEditarTarea,
  },
  data() {
    return {
      id: this.$route.params.id,
      loading: false,
      tareas: [],
      asignacion: null,
      deliveryDays: 10,
      tareaDeleteId: null,
      isEditingTarea: false,
      selectedEditData: null,
    };
  },
  validations: {
    deliveryDays: {
      required,
      minValue: minValue(0),
      integer,
    },
    tareas: {
      $each: {
        precioContratista: {
          required,
        },
      },
    },
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapState(["datosUsuarioLogueado"]),
    esAdmin: function() {
      return parseInt(this.datosUsuarioLogueado.tipoUsuario) === 1;
    },
    esSupervisor: function() {
      return parseInt(this.datosUsuarioLogueado.tipoUsuario) === 2;
    },
    esContratista: function() {
      return parseInt(this.datosUsuarioLogueado.tipoUsuario) === 3;
    },
  },
  methods: {
    validate() {
      this.$v.$touch();
      var isValid = !this.$v.$invalid;
      this.$emit("on-validate", this.tareas, this.deliveryDays, isValid);

      if (!isValid) {
        const h = this.$createElement;

        const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
          h("b-spinner", { props: { type: "grow", small: true } }),
          ` Debe ingresar todos los precios como contratista y el tiempo estimado de entrega.`,
          h("b-spinner", { props: { type: "grow", small: true } }),
        ]);
        // Create the title
        const vNodesTitle = h(
          "div",
          {
            class: ["d-flex", "flex-grow-1", "align-items-baseline", "mr-2"],
          },
          [h("strong", { class: "mr-2" }, "Debe ingresar precios")]
        );

        this.$bvToast.toast([vNodesMsg], {
          title: [vNodesTitle],
          solid: true,
          variant: "danger",
        });
      }

      return isValid;
    },
    eliminarTareaRegistrada(tareaId) {
      this.tareaDeleteId = Number(tareaId);
      this.$bvModal.show("modal-eliminar-tarea");
    },
    setSubtotal(precio, cantidad) {
      return parseFloat(precio) * parseInt(cantidad);
    },
    privateOpenEditTarea(tarea) {
      this.isEditingTarea = true;
      this.selectedEditData = tarea;
    },

    init() {
      return new Promise((resolve) => {
        this.getInfo().then(() => {
          this.getTareas();

          resolve();
        });
      });
    },
    getInfo() {
      return new Promise((resolve) => {
        this.$apollo
          .query({
            query: GetAsignaciones,
            variables: {
              codigoTicket: this.id,
            },
            fetchPolicy: "no-cache",
          })
          .then((response) => {
            this.asignacion = response.data.GetAsignaciones;
            this.deliveryDays = this.asignacion.tiempoEntrega

            resolve();
          });
      });
    },

    getTareas() {
      this.loading = true;

      this.$apollo
        .query({
          query: GetAllTareas,
          variables: {
            numberPaginate: 100,
            page: 1,
            codigoTicket: this.id,
            estado: "",
          },
          errorPolicy: "all",
          fetchPolicy: "no-cache",
        })
        .then((res) => {
          this.tareas = res.data.GetAllTareas.data;

          this.tareas.forEach((item) => {
            item.copiaPrecioContratista = item.precioContratista;
          });

          this.loading = false;
        });
    },
  },
};
</script>
